import './Plorbin.css'
import './PlorbinEffects.css'


function Plorbin() {
  // `PlorbinPic` is a component
  // containing the tunnel img element
  const PlorbinPic = () => {
    return <div id="plorbin-pic"></div>
  }


  // `PlorbinText` is a component 
  // containing plorbin text :P
  const PlorbinText = () => {
    // String options for PlorbinText
    const plorbinTextOptions = [
      'plorbin.',
      'ploOoOrbin',
      'plorbin :3',
      'plorbin >:(',
      'PLORBIN B)',
      'plorbin ;P',
      'plorb.',
      'plooorbiiin',
      'plorbin.com',
      'plörbĩn',
      'plrbn',
      'plor...bin...',
      'plorb uwu',
      'plorbin!'
    ]

    // Get random index for `plorbinTextOptions`
    const plorbinTextOption = plorbinTextOptions[Math.floor(Math.random() * plorbinTextOptions.length)]

    // Get random index for `plorbinEffect`
    const currentEffectOptions = 8
    const plorbinEffect = Math.floor(Math.random() * currentEffectOptions) + 1

    // Return div#plorbin-text with random plorbinTextOption
    return <div id="plorbin-text"><p className={`plorbin-effect-${plorbinEffect}`} data-shadow={plorbinTextOption}>{plorbinTextOption}</p></div>
  }


  console.image = (url) =>{
    var xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.responseType = 'blob';
    xhr.onload = function(){
        var fr = new FileReader();

        fr.onload = function(){
            const style = `font-size: 300px; background-image: url("${this.result}"); background-size: contain; background-repeat: no-repeat;`;
            console.log("%c     ", style);
        };
        fr.readAsDataURL(xhr.response); // async call
    };
    xhr.send();
  }

  // `generateContent()` returns the Plorbin
  // Tunnel pic ~10% of the time randomly
  // and returns `PlorbinText` otherwise
  const generateContent = () => {
    // get a `randomNumber` (0-99)
    const randomNumber = Number(String(Math.random()).slice(-2))

    // if `randomNumber` is greater than 98
    // return `PlorbinPic` componenent
    if (randomNumber > 98) {
      console.log('%chehe made by sillypilled :P','color:pink;font-family:cursive')
      return PlorbinPic()
    }
    else {
      console.image("https://t3.ftcdn.net/jpg/00/71/14/04/360_F_71140435_A4kGltL08ggcZeSShPvrWdX8hk61Qr3R.jpg")

      console.log('%cplorb in plorb IP:','color:black; background-color:red; font-size:1.5em; font-weight:bold; font-family:monospace; padding: 10px;')

      const randomIP = (Math.floor(Math.random() * 255) + 1)+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))+"."+(Math.floor(Math.random() * 255))
      console.log(`%c🚨 ${randomIP} 🚨`,'color:red; background-color:black; font-size:2em; font-weight:bold; padding: 10px;')
      
      return PlorbinText()
    }
  }


  return (
    <div className="plorbin">
      <div className="plorbin-content">
        {generateContent()}
      </div>
    </div>
  )
}


export default Plorbin