import React from 'react'
import ReactDOM from 'react-dom/client'

import './index.css'

import Plorbin from './Plorbin/Plorbin'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Plorbin />
    {/* <CollectAnalytics /> */}
  </React.StrictMode>
)

const titleScroller = (text) => {
  document.title = 'ｐＬｏＯｏＯｏＯｏＯｒＢｉＮ ◦'
  window.setInterval(function() {
    document.title = document.title.substring(1) + document.title[0]
  }, 250)
}
titleScroller()

